import { useLocation, useNavigate } from 'react-router-dom';
import styles from './admin-navigation.module.css';
import { adminRoutes } from '../../constants/routes';
import { EColors } from '../../enums/colors';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ERoles } from '../../enums/roles';
export const AdminNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const me = useSelector((state: RootState) => state.me.data);

  return (
    <div className={styles.container}>
      {adminRoutes.map((route) => {
        if (route.isAdmin && me && me.role.role !== ERoles.ADMIN) {
          return null;
        } else {
          return (
            <a
              key={route.path}
              className={
                location.pathname === route.path
                  ? styles.active
                  : styles.inactive
              }
              onClick={() => navigate(route.path)}
            >
              <p
                style={{
                  color:
                    location.pathname === route.path
                      ? EColors.MEDIUM_GRAY
                      : EColors.DUSTY_GRAY,
                  fontSize: '22px',
                }}
              >
                {route.name}
              </p>
            </a>
          );
        }
      })}
    </div>
  );
};
