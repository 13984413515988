import { Route, Routes, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { AdminLayout } from '../components/AdminLayout/AdminLayout';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { fetchMe } from '../../features/user/userSlice';
import { PollsPage } from '../pages/PollsPage/PollsPage';
import { AdminPlaceholder } from '../components/AdminPlaceholder/AdminPlaceholder';
import { QuestionsPage } from '../pages/QuestionsPages/QuestionsPage';
import { UsersPage } from '../pages/UsersPage/UsersPage';
import { NotFound } from '../components/NotFound/NotFound';
import { EstimatesPage } from '../pages/EstimatesPage/EstimatesPage';

export const AdminRoutes = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { data: me, loading } = useSelector((state: RootState) => state.me);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');

    if (!jwt) {
      navigate('/login');
      return;
    }
    if (!me) {
      console.log('fetching');
      dispatch(fetchMe(jwt));
    }
  }, [me]);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <AdminLayout>
      <Routes>
        <Route path="/" element={<AdminPlaceholder />} />
        <Route path="/polls" element={<PollsPage />} />
        <Route path="/questions" element={<QuestionsPage />} />
        <Route path="/users" element={<UsersPage />} />
        <Route path="/estimates" element={<EstimatesPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AdminLayout>
  );
};
