import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Poll } from '../components/Poll/Poll';
import { NotFound } from '../components/NotFound/NotFound';
import { Success } from '../components/Success';
import React from 'react';
import { AdminRoutes } from './AdminRoutes';
import { ERoutes } from '../constants/routes';
import { Login } from '../components/Login/Login';

export const BaseRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ERoutes.CLIENT} element={<Poll />} />
        <Route path={ERoutes.SUCCESS} element={<Success />} />
        <Route path={ERoutes.ADMIN + '/*'} element={<AdminRoutes />} />
        <Route path={ERoutes.NOT_FOUND} element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
