import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import styles from './users-page.module.css';
import { PrimaryButton } from '../../components/Button/PrimaryButton';
import { Modal } from '../../components/Modal/Modal';
import { Button, Form, Input, Select } from 'antd';
import { EColors } from '../../enums/colors';
import { ERoles } from '../../enums/roles';
import { UsersTable } from '../../components/UsersTable/UsersTable';
import { useUser } from '../../hooks/useUser';
import { TUser } from '../../types/User';
import { useNavigate } from 'react-router-dom';
export const UsersPage = () => {
  const me = useSelector((state: RootState) => state.me.data);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const {
    getUsers,
    getUserEmails,
    users,
    userEmails,
    createUserRole,
    deleteUserRole,
  } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      console.log(me);
      if (me?.role.role === ERoles.ADMIN) {
        await getUsers();
        await getUserEmails();
      }

      if (me?.role.role !== ERoles.ADMIN) {
        navigate('/admin/polls');
      }
    };
    if (me) {
      fetchData();
    }
  }, [me]);

  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.pageHeader}>Пользователи</h1>
      <div className={styles.buttonContainer}>
        <PrimaryButton
          onClick={handleOpenModal}
          text={'Cоздать пользователя'}
        />
      </div>
      <UsersTable
        users={users}
        deleteUserRole={deleteUserRole}
        isEditable={true}
      />
      <Modal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        className={styles.modal}
      >
        <p
          style={{
            fontSize: '28px',
            color: EColors.GRANITE_GRAY,
            textWrap: 'wrap',
            fontWeight: 700,
            lineHeight: '36px',
            fontFamily: 'Manrope',
          }}
        >
          Создание пользователя
        </p>
        <Form
          name="user"
          onFinish={async (values: TUser) => {
            await createUserRole(values);
            setIsModalOpen(false);
          }}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: '10px',
            justifyContent: 'start',
          }}
        >
          <Form.Item<TUser>
            name="publicKey"
            rules={[
              {
                required: true,
                message: 'Поле необходимо заполнить!',
              },
            ]}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Почта'}
              style={{ width: '283px' }}
              options={userEmails?.map((user) => {
                return {
                  value: user.email,
                  label: user.email,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          </Form.Item>
          <Form.Item<TUser>
            name="role"
            rules={[
              {
                required: true,
                message: 'Поле необходимо заполнить!',
              },
            ]}
          >
            <Select
              showSearch
              className={'select'}
              placeholder={'Роль'}
              style={{ width: '283px' }}
              options={[
                {
                  value: 'MANAGER',
                  label: 'MANAGER',
                },
                {
                  value: 'DIRECTOR',
                  label: 'DIRECTOR',
                },
              ]}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          </Form.Item>
          <Form.Item<TUser>
            name="privateKey"
            rules={[
              { required: true, message: 'Введите пароль!' },
              {
                validator: (_, value) => {
                  if (!value || /^(?=.*[A-Za-z])(?=.*\d).+$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'Пароль должен содержать как минимум одну букву и одну цифру!',
                  );
                },
              },
            ]}
          >
            <Input
              className={'select'}
              placeholder={'Пароль'}
              style={{ width: '283px' }}
            />
          </Form.Item>
          <Form.Item<TUser>
            name="rePrivateKey"
            dependencies={['privateKey']}
            rules={[
              { required: true, message: 'Повторите пароль!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('privateKey') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Пароли не совпадают!');
                },
              }),
            ]}
          >
            <Input
              className={'select'}
              placeholder={'Повторите пароль'}
              style={{ width: '283px' }}
            />
          </Form.Item>
          <Form.Item style={{ width: '100%' }}>
            <Button
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'black',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                fontFamily: 'Manrope',
              }}
              className={styles.button}
              type="primary"
              htmlType="submit"
            >
              Ок
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
