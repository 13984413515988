import React from 'react';
import { footerStyle } from '../../styles/styles';
import { Footer } from 'antd/es/layout/layout';
import styles from '../AdminLayout/layout.module.css';

export const PollFooter = () => {
  return (
    <Footer style={footerStyle}>
      <div className={styles.footerContainer}>
        <img src={'/logo.svg'} alt={'logo'} />
        <p className={styles.footerText}>© 2000 - 2024 Артикс</p>
      </div>
      <div className={styles.socialsContainer}>
        <div
          onClick={() => {
            window.open('https://t.me/truedigitalclub', '_blank');
          }}
          className={styles.social}
        >
          <img
            className={styles.socialImage}
            src={'/telegram.svg'}
            alt={'telegram'}
          />
        </div>
        <div
          onClick={() => {
            window.open('https://vk.com/articsru', '_blank');
          }}
          className={styles.social}
        >
          <img className={styles.socialImage} src={'/vk.svg'} alt={'vk'} />
        </div>
        <div
          onClick={() => {
            window.open('https://truedigital.ru/', '_blank');
          }}
          className={styles.social}
        >
          <img
            className={styles.socialImage}
            src={'/trueDigital.svg'}
            alt={'vk'}
          />
        </div>
      </div>
    </Footer>
  );
};
