import styles from './AdminLayout/layout.module.css';
import { Footer } from 'antd/es/layout/layout';
import { footerStyle } from '../styles/styles';
import React from 'react';
import { PollFooter } from './PollFooter/PollFooter';

export const Success = () => {
  return (
    <div
      style={{
        background: '#FFFFFF',
        display: 'flex',
        height: '100%',
        width: '100%',
        position: 'relative',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowX: 'hidden',
      }}
    >
      {/*<div*/}
      {/*  style={{*/}
      {/*    height: '100%',*/}
      {/*    width: '100%',*/}
      {/*    position: 'relative',*/}
      {/*    display: 'flex',*/}
      {/*    flexDirection: 'row',*/}
      {/*  }}*/}
      {/*>*/}
      <div className={styles.logo}>
        <img src={'/red-logo.svg'} className={styles.logoImage} alt={'logo'} />
      </div>
      <div className={styles.backgroundContainer}>
        <img
          src={'/fon.png'}
          className={styles.backgroundImageSuccess}
          alt={'background'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          height: '-webkit-fill-available',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1 style={{ color: '#3F444F' }}>Спасибо за оставленный отзыв!</h1>
      </div>
      {/*</div>*/}
      <PollFooter />
    </div>
  );
};
